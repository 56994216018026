import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { format } from "date-fns";
import { hi } from "date-fns/locale";
import { Link } from "react-router-dom";
import { Circles } from 'react-loader-spinner';


const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
  }
  return text;
};

const stripHtmlTags = (html, maxLength) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const strippedText = tempDiv.textContent || tempDiv.innerText || "";
  return truncateText(strippedText, maxLength);
};

export default function AllHotNews() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.sadaivsatya.com/api/home/frontKhabars"
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  // Define your base URL for news images
  const formatDateInHindi = useCallback((dateString) => {
    const date = new Date(dateString);
    const monthsInHindi = [
      "जनवरी",
      "फरवरी",
      "मार्च",
      "अप्रैल",
      "मई",
      "जून",
      "जुलाई",
      "अगस्त",
      "सितंबर",
      "अक्टूबर",
      "नवंबर",
      "दिसंबर",
    ];
    const day = date.getDate();
    const month = monthsInHindi[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }, []);
  const generateShareLinks = useCallback((mainKhabarId, newsHeading) => {
    const encodedNewsHeading = encodeURIComponent(newsHeading);
    const url = `${window.location.origin}/MadhyaPradeshDetailsD/${mainKhabarId}`;
    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedNewsHeading}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodedNewsHeading} ${url}`
    };
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Circles
          height="30"
          width="30"
          color="#f7265c"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  const baseUrl = "https://api.sadaivsatya.com/";
  const image_resize = "https://api.sadaivsatya.com/api/home/resize";

  return (
    <div>
      <div className="container">

        {posts.frontKhabars.slice(0, 1).map((newsItem) => {
          const shareLinks = generateShareLinks(newsItem.mainKhabarId, newsItem.newsHeading);
          return (

            <article key={newsItem.mainKhabarId}
 className="ak-module ak-module-1-medium clearfix post-226 post type-post status-publish format-standard has-post-thumbnail  category-business category-featured category-sports category-work-life category-world-news tag-breaking tag-election tag-politics tag-technology tag-world-news">
              {newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges" />
                    <div className="ak-module-video-duration">
                      <div className="active">Watch</div>
                    </div>
                    <span className="ak-module-format-icon format-video">
                      <i className="ak-icon fa fa-play" />
                    </span>
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Robot companies pledge they’re not going to let the robots kill you"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb">
                          <video
                            controls
                            autoPlay
                            style={{ height: "250px", width: "350px" }}
                          >
                            <source
                              src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-43" to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Robot companies pledge they’re not going to let the robots kill you"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 100)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today"
                          className="ak-module-meta-published"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges">
                      <Link to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} title="Featured" aria-label="Shabd Today">
                        <span className="ak-badge-icon ak-badge-type-icon term-43">
                          <i className="ak-icon ak-badge-icon-i ak-fi akfi-trending_up" />
                        </span>
                      </Link>
                    </div>
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Robot companies pledge they’re not going to let the robots kill you"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb size-715">
                          <img
                            loading="eager"
                            decoding="async"
                            width={270}
                            height={270}
                            src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            className="attachment-newsy_350x250 size-newsy_350x250 lazyload wp-post-image"
                            alt={stripHtmlTags(newsItem.newsHeading, 100)}
                            data-src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            data-sizes="auto"
                            data-srcset={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            data-expand={700}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-43" to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Robot companies pledge they’re not going to let the robots kill you"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 100)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today"
                          className="ak-module-meta-published"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>
                      <div className="socialmedia">
                        <Link to={shareLinks.facebook} target="_blank" aria-label="Facebook" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                        &nbsp;&nbsp;  &nbsp;&nbsp;
                        <Link to={shareLinks.whatsapp} target="_blank" aria-label="whatsapp" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </article>
          );
        })}
        {posts.frontKhabars.slice(1, 2).map((newsItem) => {
          const shareLinks = generateShareLinks(newsItem.mainKhabarId, newsItem.newsHeading);
          return (
            <article  key={newsItem.mainKhabarId} className="ak-module ak-module-1-medium clearfix ak-exclusive-post post-227 post type-post status-publish format-standard has-post-thumbnail  category-entertainment category-tech-science category-work-life category-world-news tag-breaking tag-election tag-politics tag-technology tag-world-news ak-exclusive-article">
              {newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges" />
                    <div className="ak-module-video-duration">
                      <div className="active">Watch</div>
                    </div>
                    <span className="ak-module-format-icon format-video">
                      <i className="ak-icon fa fa-play" />
                    </span>
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Everything you need to know about Amazon’s Prime Early Access Sale next week"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb">
                          <video
                            controls
                            autoPlay
                            // style={{ height: "250px", width: "350px" }}
                          >
                            <source
                              src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-50" to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Everything you need to know about Amazon’s Prime Early Access Sale next week"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 100)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today"
                          className="ak-module-meta-published"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges" />
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Everything you need to know about Amazon’s Prime Early Access Sale next week"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb size-715">
                          <img
                            loading="eager "
                            decoding="async"
                            width={270}
                            height={270}
                            src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            className="attachment-newsy_350x250 size-newsy_350x250 lazyload wp-post-image"
                            alt={stripHtmlTags(newsItem.newsHeading, 100)}
                            data-src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            data-sizes="auto"
                            data-srcset={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=270&h=270&outtype=webp"}
                            data-expand={700}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-50" to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Everything you need to know about Amazon’s Prime Early Access Sale next week"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 100)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          tto={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                          className="ak-module-meta-published"
                          aria-label="Shabd Today"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>
                      <div className="socialmedia">
                        <Link to={shareLinks.facebook} target="_blank" aria-label="Facebook" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                        &nbsp;&nbsp;  &nbsp;&nbsp;
                        <Link to={shareLinks.whatsapp} target="_blank" aria-label="whatsapp" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </article>
          );
        })}
        {posts.frontKhabars.slice(2, 3).map((newsItem) => {
          const shareLinks = generateShareLinks(newsItem.mainKhabarId, newsItem.newsHeading);
          return (
            <article  key={newsItem.mainKhabarId} className="ak-module ak-module-1-medium clearfix post-228 post type-post status-publish format-standard has-post-thumbnail  category-business category-entertainment category-featured category-us-news category-work-life tag-breaking tag-election tag-politics tag-technology tag-world-news">
              {newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges" />
                    <div className="ak-module-video-duration">
                      <div className="active">Watch</div>
                    </div>
                    <span className="ak-module-format-icon format-video">
                      <i className="ak-icon fa fa-play" />
                    </span>
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb">
                          <video
                            controls
                            autoPlay
                            style={{ height: "250px", width: "350px" }}
                          >
                            <source
                              src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=376&h=321&outtype=webp"}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-45" to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 90)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today"
                          className="ak-module-meta-published"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!newsItem.newsImage.endsWith(".mp4") && (
                <div className="ak-module-inner clearfix">
                  <div className="ak-module-featured">
                    <div className="ak-module-badges" />
                    <div className="ak-featured-cover">
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        className="ak-featured-link"
                        rel="bookmark"
                        title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                        aria-label="Shabd Today"
                      >
                        <div className="ak-featured-thumb lazy-thumb size-715">
                          <img
                            loading="eager "
                            decoding="async"
                            width={270}
                            height={270}
                            src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=376&h=321&outtype=webp"}
                            className="attachment-newsy_350x250 size-newsy_350x250 lazyload wp-post-image"
                            alt={stripHtmlTags(newsItem.newsHeading, 100)}
                            data-src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=376&h=321&outtype=webp"}
                            data-sizes="auto"
                            data-srcset={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=376&h=321&outtype=webp"}
                            data-expand={700}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="ak-module-terms badge">
                      <Link className="term-45" to="#" aria-label="Shabd Today">
                        {stripHtmlTags(newsItem.newsTag, 20)}{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="ak-module-details">
                    <h1 className="ak-module-title" style={{ fontSize: "16px" }}>
                      <Link
                        to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`}
                        rel="bookmark"
                        title="Do We Really Need To Wear Hair Products That Contain Sunscreen?"
                        aria-label="Shabd Today"
                      >
                        {stripHtmlTags(newsItem.newsHeading, 90)}
                      </Link>
                    </h1>
                    <div className="ak-module-summary">
                      <p>{stripHtmlTags(newsItem.newsDetails, 200)}</p>
                    </div>
                    <div className="ak-module-meta gayab">
                      <div className="ak-module-time">
                        <Link
                          to={`/MadhyaPradeshDetailsD/${newsItem.mainKhabarId}`} aria-label="Shabd Today"
                          className="ak-module-meta-published"
                        >
                          <i className="ak-icon akfi-schedule" />
                          {formatDateInHindi(newsItem.date)}
                        </Link>
                      </div>
                      <div className="ak-module-view-count">
                        <span style={{ color: "#000000" }}>
                          <i className="ak-icon  ak-fi akfi-power" />
                          <span className="count">{newsItem.viewCount}</span>
                        </span>
                      </div>

                      <div className="socialmedia">
                        <Link to={shareLinks.facebook} target="_blank" aria-label="Facebook" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                        &nbsp;&nbsp;  &nbsp;&nbsp;
                        <Link to={shareLinks.whatsapp} target="_blank" aria-label="whatsapp" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link>
                      </div>

                    </div>
                  </div>
                </div>
              )}
            </article>
          );
        })}
      </div>
    </div>
  );
}
